import { RootState } from '@dis/redux'

// TODO: Analyze if it's possible to replace usages this selector with hook useTypedParams
export const selectSelectedTenantId = (state: RootState) => {
  return state.tenants.selectedTenantId
}

export const selectSelectedTenantName = (state: RootState) => {
  return state.tenants.selectedTenantName
}

export const selectSelectedTenantSectorCode = (state: RootState) => state.tenants.tenant?.sectorcode

export const selectSelectedIsTemplateTenant = (state: RootState) => {
  return state.tenants.isTemplate
}

export const selectTenantDisplayType = (state: RootState) => {
  return state.tenants.tenantDisplayType
}

export const selectAtlases = (state: RootState) => state.tenants.tenant?.atlases

export const selectTenantData = (state: RootState) => state.tenants.tenant

export const selectLogoTenantMapEx = (state: RootState) => state.tenants.showLogo

export const selectTenantLimits = (state: RootState) => state.tenants.tenant?.limits

export const selectSectorCodeTenant = (state: RootState) => state.tenants.tenant?.sectorcode
