import { useCallback, useEffect, useRef, useState } from 'react'
import { selectSelectedTenantId } from '@dis/redux/src/tenants/tenantsSelectors'
import { useAppSelector } from '@dis/redux'
import { selectSelectedJourneyId } from '@dis/redux/src/journeys/journeysSelectors'
import { selectSelectedPersonaId } from '@dis/redux/src/personas/personasSelectors'
import { PersonaDetailType } from '@dis/types/src/PersonaTypes'
import {
  WsEnhancedResponseMessage,
  WsMessageModel,
  WsOperationType,
  WsVersioningMessage,
} from '@dis/types/src/wsModels'
import { WsVersionList } from '@dis/types/src/VersioningType'
import { JourneySubscribe } from '@dis/modules/src/journey/grid/types'
import { Api } from '@dis/api'

type UseVersionlistProps = {
  isJourney: boolean
  isPersona: boolean
  semaphore: boolean
}

export const useVersionlist = ({ isPersona, isJourney, semaphore }: UseVersionlistProps) => {
  const [data, setData] = useState<WsVersionList>()
  const [loading, setLoading] = useState(true)
  const [iteration, setIteration] = useState(0)

  const selectedTenantId = useAppSelector(selectSelectedTenantId)
  const selectedJourneyId = useAppSelector(selectSelectedJourneyId)
  const selectedPersonaId = useAppSelector(selectSelectedPersonaId)

  const onMessage = useCallback(
    (newData: WsEnhancedResponseMessage<WsVersionList>) => {
      setData({
        versions: (newData.data?.versions || []).sort((a, b) => a.hours - b.hours),
      })
      setLoading(false)
    },
    [setData, setLoading],
  )

  const refetch = useCallback(() => {
    setIteration(iteration + 1)
  }, [iteration, setIteration])

  useEffect(() => {
    // Keep data always up-to-date
    if (!semaphore) {
      setData(undefined)
    }

    if (
      semaphore &&
      selectedTenantId &&
      ((isJourney && selectedJourneyId) || (isPersona && selectedPersonaId))
    ) {
      setLoading(true)

      Api.sendVersionlist(
        {
          [WsOperationType.VersionList]: {
            id: isPersona ? selectedPersonaId?.toString() : selectedJourneyId?.toString(),
            model: isPersona ? WsMessageModel.Persona : WsMessageModel.Document,
          },
          tenantId: selectedTenantId,
        },
        onMessage,
      )
    }
  }, [
    isJourney,
    isPersona,
    iteration,
    onMessage,
    selectedJourneyId,
    selectedPersonaId,
    selectedTenantId,
    semaphore,
  ])

  return {
    data,
    loading,
    refetch,
  }
}

type UseViewVersionProps = {
  onData?: (data: WsEnhancedResponseMessage<PersonaDetailType | JourneySubscribe>) => void
}

export const useViewVersion = (props?: UseViewVersionProps) => {
  const [data, setData] = useState<WsEnhancedResponseMessage>()
  const [loading, setLoading] = useState(true)

  const onData = useRef(props?.onData)
  onData.current = props?.onData

  const onMessage = useCallback((newData?: WsEnhancedResponseMessage) => {
    if (newData) {
      onData.current?.(newData)
      setData(newData)
      setLoading(false)
    }
  }, [])

  const send = useCallback(
    (message: WsVersioningMessage) => {
      Api.sendVersion(message, onMessage)
    },
    [onMessage],
  )

  const reset = useCallback(() => {
    setData(undefined)
    setLoading(false)
  }, [])

  return {
    loading,
    resetViewVersion: reset,
    sendViewVersion: send,
    viewVersionData: data,
  }
}
