import { FC, useCallback, useEffect } from 'react'
import { Modal } from '@dis/components'
import { useTranslation } from 'react-i18next'
import { tKeys } from '@dis/languages'
import { Input, yup, useForm } from '@dis/form'
import { NewPersonaForm } from '@dis/types/src/forms'
import { useCreatePersona } from '@dis/hooks/src/useCreate'
import { useNavTo, useTypedParams } from '@dis/hooks'
import { dispatchedActions, useAppSelector } from '@dis/redux'
import { selectTenantData } from '@dis/redux/src/tenants/tenantsSelectors'
import { MAX_NAME_LENGTH } from '@dis/constants'
import { PersonaDetailType } from '@dis/types/src/PersonaTypes'

const msg = tKeys.persona.createPersona

const validationSchema = yup.object({
  name: yup.string().required(),
})

type Props = {
  hasPreventRedirect?: boolean
  isOpen: boolean
  onClose: VoidFunction
  onPersonaCreated?: (data: PersonaDetailType) => void
}

export const NewPersonaModal: FC<Props> = ({
  hasPreventRedirect,
  onClose,
  onPersonaCreated,
  isOpen,
}) => {
  const tenantData = useAppSelector(selectTenantData)

  const limits = tenantData?.limits?.maxlen?.personas

  const { t } = useTranslation()

  const { atlasId, journeyId, folderId } = useTypedParams()

  const { navigate } = useNavTo()

  const handlePersonaCreated = useCallback(
    (data: PersonaDetailType) => {
      onPersonaCreated?.(data)

      if (!hasPreventRedirect) {
        navigate('persona', { personaId: data.id })
      }
    },
    [hasPreventRedirect, navigate, onPersonaCreated],
  )

  const { sendCreate: sendCreatePersona } = useCreatePersona({
    onData: handlePersonaCreated,
  })

  const { handleSubmit, control, reset } = useForm<NewPersonaForm>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    validationSchema,
  })

  const submit = handleSubmit((data) => {
    if (!hasPreventRedirect) {
      // it is set up when we open modal from journey
      dispatchedActions.personas.setCallback({ atlasId, folderId, journeyId })
    }

    onClose()

    sendCreatePersona(data)
  })

  useEffect(() => {
    reset()
  }, [isOpen, reset])

  return (
    <Modal
      title={t(msg.title)}
      onCrossBtnClick={onClose}
      primaryBtnText={t(tKeys.common.submit)}
      onPrimaryBtnClick={submit}
      isOpen={isOpen}>
      <Input
        fixedLabel
        formControl={control}
        hoverUnderline={false}
        label={t(tKeys.common.name)}
        name="name"
        placeholder={t(msg.placeholder)}
        required
        variant="outlined"
        maxLength={limits?.name ?? MAX_NAME_LENGTH}
      />
    </Modal>
  )
}
