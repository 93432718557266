import React from 'react'
import { designPrinciplesList } from '@dis/modules/src/journey/layers/design-principles/utils'
import { JourneyChannel } from '@dis/types/src/ChannelTypes'
import { EmotionJSX } from '@emotion/react/types/jsx-namespace'
import { ResourceTypeEnum } from '@dis/types/src/JourneyTypes'
import { LayerDataItemContent, ProcessesContent } from './dataContentTypes'

type GuidInfoMapItem = {
  index: number
  width: number
}

export interface JourneyHeaderComponent<DATA = any> {
  color: string
  data: JourneyGridHeaderItem<DATA>['data']
  headerStructure: JourneyGridHeaderItem[][]
  isFirst: boolean
  isLast: boolean
  isLastInPhase: boolean
  isSingleChild: boolean
  onActionClick: (
    item: JourneyGridHeaderItem<DATA>['data'],
    actionType: HeaderActionType,
    structureIndex: number, // o jaké pole se jedná (fáze, subfáze, touchpoint)
  ) => void
  width: number
}

export interface JourneyLayerComponent<Data = LayerDataItemContent> {
  color?: string
  data?: JourneyGridLayerEntity<Data>
  entity: TargetType
  layerObject: Layer<Data>
  onActionClick: (
    item: JourneyGridLayerEntity<Data> | undefined,
    entity: TargetType,
    actionType: LayerActionType,
    payload?: any,
  ) => void
  width: number
}

export interface JourneyExtendSidebarComponent<T = LayerDataItemContent> {
  layerObject: Layer<T>
}

export interface JourneyLayerStructureItem<T = LayerDataItemContent> {
  allowMovement?: boolean
  explanation: string
  isFullWidth?: boolean
  label: string
  layerItem: JourneyGridLayerItem
  layerObject: Layer<T>
  model: LayerModelType
}

export interface JourneyLayerStructureItemComponents {
  Component: (props: JourneyLayerComponent<any>) => EmotionJSX.Element | null
  EmptyComponent?: (props: JourneyLayerComponent<any>) => EmotionJSX.Element | null
  ExtendSidebarComponent?: ({
    layerObject,
  }: {
    layerObject: Layer<any>
  }) => EmotionJSX.Element | null
  IconComponent: string
}

export interface JourneySidebarItem {
  customNameIdentifier?: string
  description?: string
  index: number
  label: string
  layerGuid: string
  layerObject?: Layer
  model?: LayerModelType
  name: string
  personaIds: number[]
}

export type CustomNameIdentifier =
  | 'customphaselabel'
  | 'customsubphaselabel'
  | 'customtouchpointlabel'

export interface JourneyHeaderModelItem {
  customNameIdentifier: CustomNameIdentifier
  dataIndex: number
  name: string
}

export type JourneyHeadreModelComponents = Record<
  CustomNameIdentifier,
  {
    Component: React.FC<JourneyHeaderComponent>
    IconComponent: string
  }
>

export interface ButtonsContainerProps {
  disabled?: boolean
  icon: React.ReactNode
  id: number
  onClick?: (e: any) => void
  position: {
    insetInlineEnd?: string
    insetInlineStart?: string
    left?: string
    right?: string
    top?: string
  }
}

export enum HeaderActionType {
  AddLeft,
  AddRight,
  Delete,
  MoveLeft,
  MoveRight,
  SwitchMot,
}

export enum LayerActionType {
  Add,
  Delete,
  MoveLeft,
  MoveRight,
}

export enum SidebarActionType {
  AddFirst,
  AddTop,
  AddBottom,
  MoveTop,
  MoveBottom,
  Delete,
  Hide,
  HidePhase,
  HideSubphase,
  HideTouchpoint,
}

export type LayerDefaults = Required<JourneyGridLayerItem>

export type GuidIndexMap = Record<Touchpoint['guid'], GuidInfoMapItem>

export type GuidColorMap = Record<Subphase['guid'], string>

export interface JourneyModelStructure {
  guidColorMap: GuidColorMap
  guidIndexMap: GuidIndexMap
  headerModel: JourneyHeaderModelItem[]
  headerStructure: JourneyGridHeaderItem<Phase | Subphase | Touchpoint>[][]
  layersStructure: JourneyLayerStructureItem[]
  sidebar: JourneySidebarItem[]
}

export type Info = {
  customphaselabel?: string
  customsubphaselabel?: string
  customtouchpointlabel?: string
  deleted: boolean
  desc?: string
  name?: string
  published: boolean
}

export enum TargetType {
  Phase = 'phase',
  Subphase = 'subphase',
  Touchpoint = 'touchpoint',
}

export enum LayerModelType {
  Backlog = 'userstory-layer',
  Capabilities = 'capabilities-layer',
  Channels = 'channels-layer',
  ConnectedCapabilities = 'connected-capabilities-layer',
  Cost = 'cost-layer',
  DesignPrinciples = 'design-principles-layer',
  DesignPrinciplesV2 = 'design-principles-v2-layer',
  EmotionalCurve = 'emotional-journey-layer',
  Expectations = 'expectations-layer',
  Gainpoint = 'gainpoint-layer',
  InlineProcesses = 'inline-processes-layer',
  Metrics = 'metrics-layer',
  Painpoint = 'painpoint-layer',
  Processes = 'processes-layer',
  SixPillars = 'six-pillars-layer',
  Solutions = 'solutions-layer',
  Statistics = 'statistics-layer',
  TextAndImage = 'text-and-image-layer',
  VoiceOfBusiness = 'voice-of-business-layer',
  WhatWeHeard = 'what-we-heard-layer',
}

export enum LayerModelTypeCell {
  Backlog = 'userstory-cell',
  ConnectedCapabilities = 'connected-capabality-cell',
  Cost = 'cost-cell',
  DesignPrinciplesOrSixPillars = 'pillars-cell',
  DesignPrinciplesV2 = 'principles-v2-cell',
  EmotionalCurve = 'emotional-journey-cell',
  InlineProcesses = 'inline-processes-cell',
  Point = 'point-cell',
  Processes = 'processes-cell',
  Solutions = 'solutions-cell',
  Statistics = 'statistics-cell',
  TextAndImage = 'text-and-image-cell',
  WhatWeHeard = 'what-we-heard-cell',
}

export interface LayerDataItem<Content = LayerDataItemContent> {
  content: Content
  expandview?: boolean
  guid: string
  model: string
  position: number
  targetType: TargetType
  targetguid: string
}

export type Layer<T = LayerDataItemContent> = {
  channels?: JourneyChannel[]
  data: LayerDataItem<T>[]
  desc?: string
  expandview?: boolean
  guid: string
  model: LayerModelType
  name?: string
  personaIds: number[]
  position: number
  visibility: Record<TargetType, boolean>
}

export type Phase = {
  guid: string
  model: string
  name: string
  position: number
}

export type Subphase = {
  guid: string
  model: string
  name: string
  position: number
  targetguid: string
}

export type Touchpoint = {
  guid: string
  model: string
  mot: boolean
  name: string
  number: string
  position: number
  targetguid: string
}

export type Threads = {
  resourceId: string
  resourceType: ResourceTypeEnum
  threadId: string
}
/**
 * Subscribe model "document" returns sideloads but model "documentCache" not
 */
export interface JourneyData {
  sideloads?: JourneySideloads[]
  subscribe: JourneySubscribe
  version: number
}

export interface JourneySubscribe {
  info?: Info
  layers?: Layer[]
  personas?: Persona[]
  phases?: Phase[]
  subphases?: Subphase[]
  threads?: Record<string, Threads>
  touchpoints?: Touchpoint[]
}

export interface JourneySideloads {
  data: {
    channels?: JourneyChannel[]
    csDnas?: {
      code: string
      icon: string
      name: string
      position: number
    }[]
    id?: string
    name?: string
    sixPillars?: SixPillar[]
  }
  model: 'atlas' | 'configuration'
}

export type LayerHeaderTypePartial = Partial<Touchpoint> | Partial<Subphase> | Partial<Phase>
export type LayerHeaderType = Touchpoint | Subphase | Phase

export interface JourneyGridHeaderItem<D = LayerHeaderType> {
  data: D
  targetguid?: string
  width: number
}

export type Touchpoints = JourneyGridHeaderItem[]

export interface JourneyGridLayerEntity<T = LayerDataItemContent> {
  data?: LayerDataItem<T>
  targetguid: string // If "data" is undefined then it's necessary to know what the entity is bound to. If "data" is not undefined then this information is possible to get from the "data".
  width: number
}

export interface JourneyGridLayerItem {
  [TargetType.Phase]?: JourneyGridLayerEntity[]
  [TargetType.Subphase]?: JourneyGridLayerEntity[]
  [TargetType.Touchpoint]?: JourneyGridLayerEntity[]
}

export interface LockedLayers {
  [TargetType.Phase]: boolean
  [TargetType.Subphase]: boolean
  [TargetType.Touchpoint]: boolean
}

// Data definitions:

export interface Point {
  bgcolor?: string | null
  fgcolor: string
  id: string
  label?: string
  position: number
}

export interface Solution {
  description?: string
  difficulty: string
  id: string
  position: number
  title?: string
}

export enum WhatWeHeardTitle {
  Author = 'author',
  AuthorDescription = 'authorDescription',
  Comment = 'comment',
  Sex = 'sex',
}

export interface Process extends LayerDataItem {
  content: ProcessesContent
  number: number
}

// prop isOther really needs also null type
export type Persona = {
  basic: []
  desc?: string
  id: number
  image?: number
  isOther?: boolean | null
  model?: string
  name?: string
}

export interface ChannelPoint {
  cnt: number
  pointState: 0 | 1 | 2 | 3
  targetCode: string
}

export type SixPillar = {
  code: string
  icon: string
  name: string
  position: number
  priority: number
}

export type DesignPrinciplesList = typeof designPrinciplesList

export interface DesignPrincipleV2Item {
  guid: string
  imageId?: number
  label?: string
}

export type BacklogItem = {
  id: number
}
