import React, { useCallback, useEffect } from 'react'
import { Modal } from '@dis/components'
import { tKeys } from '@dis/languages'
import { useTranslation } from 'react-i18next'
import { Input, Select, useForm } from '@dis/form'
import { useCreateTenant } from '@dis/hooks/src/useCreate'
import { CreateOrEditTenantForm } from '@dis/types/src/forms'
import { dispatchedActions } from '@dis/redux'
import { WsErrorMessage } from '@dis/types/src/wsModels'
import { useChangeTenant } from '@dis/hooks/src/useChange'
import { MAX_TENANT_DESCRIPTION, MAX_TENANT_NAME } from '@dis/constants'
import { useSectors } from '@dis/hooks/src/useSectors'
import { styles } from './styles'
import { validationSchema } from './validation'

type Props = {
  description?: string
  isOpen: boolean
  mode: 'create' | 'edit'
  name?: string
  onClose: VoidFunction
  sectorCode?: string
  tenantId?: number
}

const msg = tKeys.tenants.createOrEditTenantModal

export const CreateOrEditTenantModal = ({
  description,
  isOpen,
  tenantId,
  name,
  mode,
  onClose,
  sectorCode,
}: Props) => {
  const { t } = useTranslation()
  const { translatedCodelist: sectors } = useSectors()

  const onData = useCallback(() => {
    dispatchedActions.centralModalDialog.showModalDialog({
      content: t(tKeys.newTenantModal.createTenantSuccess.content),
      hasCrossClose: true,
      primaryButton: {
        btnText: t(tKeys.common.accept),
      },
      severity: 'normal',
      title: t(tKeys.newTenantModal.createTenantSuccess.title),
    })
    onClose()
  }, [onClose, t])

  const onError = useCallback(
    (error: WsErrorMessage['error']) => {
      dispatchedActions.centralModalDialog.showModalDialog({
        content: error?.message || '',
        hasCrossClose: true,
        hasFooter: false,
        severity: 'error',
        title: t(tKeys.newTenantModal.createTenantError.title),
      })
    },
    [t],
  )

  const { sendCreate } = useCreateTenant({ onData, onError })
  const { sendChange } = useChangeTenant()

  const {
    control: formControl,
    handleSubmit,
    reset: resetForm,
  } = useForm<CreateOrEditTenantForm>({
    defaultValues: { sectorcode: undefined },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    validationSchema,
  })

  const onSubmitForm = handleSubmit((formData) => {
    if (mode === 'create') {
      sendCreate(formData)
      onClose()
    } else if (tenantId) {
      sendChange(formData, tenantId)
      onClose()
    }
  })

  useEffect(() => {
    if (isOpen && mode === 'edit') {
      resetForm({
        description: description || '',
        sectorcode: sectorCode || '',
        tenantName: name || '',
      })
    }
  }, [description, isOpen, mode, name, resetForm, sectorCode])

  return (
    <Modal
      isOpen={isOpen}
      title={mode === 'create' ? t(msg.createNewTenant) : t(msg.editTenant)}
      onCrossBtnClick={onClose}
      primaryBtnText={
        mode === 'create'
          ? t(tKeys.common.submit)
          : t(tKeys.tenants.createOrEditTenantModal.complete)
      }
      onPrimaryBtnClick={onSubmitForm}>
      <div className={styles.editTenantModal}>
        <Input
          autoFocus={isOpen}
          fixedLabel
          formControl={formControl}
          fullWidth
          placeholder={t(tKeys.common.enterName)}
          label={t(tKeys.common.name)}
          name="tenantName"
          required
          variant="outlined"
          maxLength={MAX_TENANT_NAME}
          isLimitVisible
          showLimitOnTop
        />
        <Select
          placeholder={t(tKeys.newTenantModal.chooseSector)}
          name="sectorcode"
          fixedLabel
          containerSx={{ mt: 5 }}
          label={t(tKeys.newTenantModal.tenantSector)}
          formControl={formControl}
          options={sectors}
          dropdownClasses={styles.selectSector}
          variant="outlined"
          disabled={mode === 'edit'}
          required
        />
        <Input
          fixedLabel
          formControl={formControl}
          fullWidth
          placeholder={t(tKeys.common.description)}
          label={t(tKeys.common.description)}
          name="description"
          variant="outlined"
          rows={4}
          multiline
          containerSx={{ mt: 5 }}
          maxLength={MAX_TENANT_DESCRIPTION}
          isLimitVisible
          showLimitOnTop
        />
      </div>
    </Modal>
  )
}
