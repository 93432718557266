import { useCallback, useEffect } from 'react'
import { IconButton, Menu } from '@mui/material'
import { useAnchor } from '@dis/hooks/src/useAnchor'
import HistoryForward from '@dis/assets/src/icons/HistoryForward.svg'
import HistoryBack from '@dis/assets/src/icons/HistoryBack.svg'
import HistoryIcon from '@dis/assets/src/icons/HistoryIcon.svg'
import ApproveHistoryIcon from '@dis/assets/src/icons/ApproveHistoryIcon.svg'
import DeclineHistoryIcon from '@dis/assets/src/icons/DeclineHistoryIcon.svg'
import { SvgImage } from '@dis/components'
import { colors } from '@dis/styles'
import { useAppSelector } from '@dis/redux'
import { selectIsLanguageRtl } from '@dis/redux/src/user/selectors'
import { cx } from '@emotion/css'
import {
  selectHistoryVersionPreviewMode as selectJourneyHistoryVersionPreviewMode,
  selectIsChangeRunning,
  selectIsJourneyBackEnable,
  selectIsJourneyForwardEnable,
  selectJourneySignatureVersion,
} from '@dis/redux/src/journeys/journeysSelectors'
import {
  selectHistoryVersionPreviewMode as selectPersonaHistoryVersionPreviewMode,
  selectIsPersonaBackEnable,
  selectIsPersonaForwardEnable,
  selectPersonaVersion,
} from '@dis/redux/src/personas/personasSelectors'
import { VersionListItems } from './VersionListItems'
import { useRevisionControlVersion } from './useRevisionControlVersion'
import { styles } from './styles'

type RevisionControlProps = {
  isJourney: boolean
  isPersona: boolean
}

export const RevisionControl = ({ isPersona, isJourney }: RevisionControlProps) => {
  const { anchorEl, handleOpen, handleClose } = useAnchor()

  const {
    isVersionListLoading,
    acceptSelectedVersion,
    onClickSetVersion,
    onClickSetVersionBack,
    onClickSetVersionForward,
    selectedVersionIndex,
    declineSelectedVersion,
    versions,
  } = useRevisionControlVersion({
    handleCloseForMenuItem: handleClose,
    isJourney,
    isPersona,
    semaphore: Boolean(anchorEl),
  })

  const isRtl = useAppSelector(selectIsLanguageRtl)
  const isPersonaForwardEnable = useAppSelector(selectIsPersonaForwardEnable)
  const isPersonaBackEnable = useAppSelector(selectIsPersonaBackEnable)
  const isJourneyForwardEnable = useAppSelector(selectIsJourneyForwardEnable)
  const isJourneyBackEnable = useAppSelector(selectIsJourneyBackEnable)
  const isJourneyChangeRunning = useAppSelector(selectIsChangeRunning)
  const journeyVersion = useAppSelector(selectJourneySignatureVersion)
  const personaVersion = useAppSelector(selectPersonaVersion)
  const personaHistoryVersionPreviewMode = useAppSelector(selectPersonaHistoryVersionPreviewMode)
  const journeyHistoryVersionPreviewMode = useAppSelector(selectJourneyHistoryVersionPreviewMode)

  const isForwardEnable = isPersona ? isPersonaForwardEnable : isJourneyForwardEnable
  const isBackEnable = isPersona ? isPersonaBackEnable : isJourneyBackEnable
  const historyVersionPreviewMode = isPersona
    ? personaHistoryVersionPreviewMode
    : journeyHistoryVersionPreviewMode

  const isBackDisabled =
    !isBackEnable || isJourneyChangeRunning || historyVersionPreviewMode === 'time'
  const isForwardDisabled =
    !isForwardEnable || isJourneyChangeRunning || historyVersionPreviewMode === 'time'
  const isTimeDisabled = isJourneyChangeRunning || historyVersionPreviewMode === 'backForward'

  const getIconColor = useCallback((disabled: boolean) => {
    return disabled ? colors.gray.gray60 : colors.gray.gray0
  }, [])

  useEffect(() => {
    if (!isForwardEnable) {
      declineSelectedVersion()
    }
  }, [declineSelectedVersion, isForwardEnable])

  return (
    <div
      className={cx(styles.container.root, {
        [styles.container.rtl]: isRtl,
      })}>
      <IconButton
        onClick={
          isPersona ? onClickSetVersionBack(personaVersion) : onClickSetVersionBack(journeyVersion)
        }
        disabled={isBackDisabled}
        sx={{ padding: 0 }}>
        <SvgImage src={HistoryBack} color={getIconColor(isBackDisabled)} />
      </IconButton>

      <IconButton
        disabled={isTimeDisabled}
        disableRipple
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleOpen}
        sx={{ padding: 0 }}>
        <SvgImage src={HistoryIcon} color={getIconColor(isTimeDisabled)} />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}>
        <VersionListItems
          isVersionListLoading={isVersionListLoading}
          versions={versions}
          onClickSetVersion={onClickSetVersion}
          handleClose={handleClose}
        />
      </Menu>

      <IconButton
        onClick={onClickSetVersionForward}
        disabled={isForwardDisabled}
        sx={{ padding: 0 }}>
        <SvgImage src={HistoryForward} color={getIconColor(isForwardDisabled)} />
      </IconButton>

      {selectedVersionIndex !== undefined && (
        <>
          <div className={styles.buttonsContainer}>
            <IconButton className={styles.buttonHistory} onClick={acceptSelectedVersion}>
              <SvgImage color={colors.kpmg.pacificBlue} src={ApproveHistoryIcon} />
            </IconButton>
            <IconButton className={styles.buttonHistory} onClick={declineSelectedVersion}>
              <SvgImage color={colors.semantic.red} src={DeclineHistoryIcon} />
            </IconButton>
          </div>
        </>
      )}
    </div>
  )
}
