import { useEffect, useMemo, useState } from 'react'
import { Sectors } from '@dis/types/src/AxiosTypes'
import { AxiosInstances } from '@dis/api'
import { sectorsUrl } from '@dis/api/src/endpoints'
import { tKeys } from '@dis/languages'
import { useTranslation } from 'react-i18next'

export const useSectors = () => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(undefined)
  const [data, setData] = useState<Sectors>([])

  const { t } = useTranslation()

  useEffect(() => {
    let active = true
    setLoading(true)
    setError(undefined)

    AxiosInstances.axiosInstance.get<Sectors>(sectorsUrl).then(
      (response) => {
        const data = (response.data || []).sort((a, b) => a.name.localeCompare(b.name))
        if (active) {
          setData(data)
          setLoading(false)
        }
      },
      (error) => {
        if (active) {
          setError(error)
          setLoading(false)
        }
      },
    )

    return () => {
      active = false
    }
  }, [setData, setError, setLoading])

  const translatedCodelist = useMemo(
    () =>
      data
        .map((item) => ({
          label: t(
            tKeys.tenantSectorDropdown[item.code as keyof typeof tKeys.tenantSectorDropdown],
          ),
          value: item.code,
        }))
        .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase())),
    [data, t],
  )

  return {
    data,
    error,
    loading,
    translatedCodelist,
  }
}
