import { Store, RecordSource, Environment, Network, Observable, FetchFunction } from 'relay-runtime'
import { ExtendedAxiosRequestConfig, ExtendedAxiosResponse } from '@dis/types/src/api'
import { GraphQLResponse } from 'relay-runtime/lib/network/RelayNetworkTypes'
import { AxiosInstances } from '../axios'
import persistedQueryDefinitionsJson from './gql/PersistedQueryDefinitions.json'

const persistedQueryDefinitions: Record<string, string> = persistedQueryDefinitionsJson

let uri = ''
let disablePersistedQueries = false

const fetchFunction: FetchFunction = (params, variables) => {
  const axiosRequestConfig: ExtendedAxiosRequestConfig<any> = {
    avoidNetworkError: false,
    data: {
      metadata: {
        ...params.metadata,
        operationKind: params.operationKind,
        operationName: params.name,
      },
      variables,
    },
    method: 'post',
    url: uri,
  }

  if (!params.id) {
    throw new Error(`Invalid GraphQL query definition for '${params.name}', missing ID parameter.`)
  }

  if (disablePersistedQueries) {
    const queryDefinition = persistedQueryDefinitions[params.id]
    if (!queryDefinition) {
      throw new Error(
        `Missing GraphQL query definition for '${params.name}' with ID = '${params.id}'.`,
      )
    }

    axiosRequestConfig.data.query = queryDefinition
  } else {
    axiosRequestConfig.data.id = params.id
  }

  const axiosResponsePromise = AxiosInstances.axiosInstance.request(axiosRequestConfig)

  return Observable.from(
    axiosResponsePromise.then((axiosResponse) => {
      const { status, data, statusText, OK } = axiosResponse as ExtendedAxiosResponse

      return new Promise((resolve, reject) => {
        if (OK) {
          return data?.errors ? reject(data) : resolve(data)
        } else {
          const ret: GraphQLResponse = {
            data: data?.data || undefined,
            errors: data?.errors || [{ message: `Status ${status}: ${statusText}` }],
            extensions: data?.extensions,
          }

          return reject(ret)
        }
      })
    }),
  )
}

/**
 * Creates a new Relay environment instance for managing (fetching, storing) GraphQL data.
 */
function createEnvironment() {
  const network = Network.create(fetchFunction)

  const store = new Store(new RecordSource())

  return new Environment({
    network,
    store,
  })
}

export const reactRelayEnvironment = createEnvironment()

export const setRelayClientUri = (newUri: string) => {
  uri = newUri
}

export const disableRelayClientPersistedQueries = () => {
  disablePersistedQueries = true
}
