import { useEffect, useState } from 'react'
import { dispatchedActions, store } from '@dis/redux'
import { Api, AxiosInstances } from '@dis/api'
import { generalErrorModals } from '@dis/modals/src/generalErrorModals'
import { ErrorCode } from '@dis/constants'
import { yup } from '@dis/form'
import {
  setRelayClientUri,
  disableRelayClientPersistedQueries,
} from '@dis/api/src/graphqlClient/relayClient'
import { useGetConfigJSONQuery } from '@dis/hooks/src/useGetConfigJSONQuery'
import { validationSchema } from './validator'

// it loads config.json and sets it to redux store when it's valid
export const useConfig = () => {
  const [isAppReady, setAppReady] = useState(false)

  const { isLoading, data, error } = useGetConfigJSONQuery()

  useEffect(() => {
    if (error) {
      generalErrorModals[ErrorCode.UnavailableConfigJson]()
    }
    if (!isLoading && data) {
      const isValid = validationSchema.isValidSync(data, { strict: true })

      if (isValid) {
        dispatchedActions.api.setRestApi(!!data.FEATURE_FLAGS.REST_API)
        dispatchedActions.api.setWebsocketTimeout(data.WEBSOCKET_TIMEOUT)

        dispatchedActions.general.setConfigData({
          azureAuthorityUrl: data.AZURE_AUTHORITY_URL || '',
          beUrl: data.BE_URL,
          clientId: data.CLIENT_ID,
        })

        dispatchedActions.security.setFeatureFlags({
          isArabicLanguageAllowed: data.FEATURE_FLAGS?.ARABIC_LANGUAGE ?? false,
          isConnectedCapabilitiesAllowed: data.FEATURE_FLAGS?.CONNECTED_CAPABILITIES ?? false,
        })

        AxiosInstances.init(store)
        Api.setAxiosInstance(AxiosInstances.axiosInstance)

        setRelayClientUri(`${data.BE_URL}/graphql/`)

        if (data.DISABLE_PERSISTED_QUERIES) {
          disableRelayClientPersistedQueries()
        }

        setAppReady(true)
      } else {
        validationSchema
          .validate(data, { abortEarly: false, strict: true })
          .catch((errors: yup.ValidationError) => {
            const errorMessage = errors.inner
              .map(({ path, message }) => path + ' ' + message)
              .join(', ')

            generalErrorModals[ErrorCode.InvalidConfigJson](errorMessage)
          })
      }
    }
  }, [data, error, isLoading])

  return { isAppReady }
}
