import {
  JourneyModelStructure,
  JourneyData,
  LockedLayers,
} from '@dis/modules/src/journey/grid/types'
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { WsEnhancedErrorMessage } from '@dis/types/src/wsModels'
import { defaultLockedLayers } from '@dis/modules/src/journey/common/constants'
import { AdministrationTenant } from '@dis/types/src/TenantTypes'
import { ChannelCodeTypeMap, ChannelEnum, ManagementChannel } from '@dis/types/src/ChannelTypes'
import { UserStory } from '@dis/types/src/UserStoryTypes'
import { CapabilityList } from '@dis/types/src/CapabilityTypes'
import { JourneySignature } from '@dis/types/src/JourneyTypes'

export type StateChannels = {
  [ChannelEnum.GlobalChannel]?: ManagementChannel[]
  [ChannelEnum.LocalChannel]?: ManagementChannel[]
}

type JourneysReducerState = {
  canEdit: boolean
  capabilities?: CapabilityList
  channels: StateChannels
  channelsCodeTypeMap?: ChannelCodeTypeMap
  channelsEditLayerId?: string
  disallowDnd: boolean
  forceRefetchFlag: boolean
  historyVersionPreviewMode?: 'backForward' | 'time' | undefined
  isBackEnable: boolean
  isChangeRunning: boolean
  isForwardEnable: boolean
  isHistoryVersionPreview: boolean
  isManagementOpen: boolean
  journeyData?: JourneyData
  journeySignature?: JourneySignature
  journeysStructure?: JourneyModelStructure
  lockedLayers: LockedLayers
  selectedJourneyId?: number
  selectedJourneyName?: string
  tenantLimits?: AdministrationTenant['limits']
  userStories: UserStory[]
  wsError?: WsEnhancedErrorMessage['error']
}

const initialState: JourneysReducerState = {
  canEdit: false,
  channels: {
    global: [],
    local: [],
  },
  disallowDnd: false,
  forceRefetchFlag: false,
  historyVersionPreviewMode: undefined,
  isBackEnable: false,
  isChangeRunning: false,
  isForwardEnable: false,
  isHistoryVersionPreview: false,
  isManagementOpen: false,
  journeySignature: undefined,
  lockedLayers: defaultLockedLayers,
  userStories: [],
}

export const journeySlice = createSlice({
  initialState,
  name: 'journeys',
  reducers: {
    init: () => initialState,
    setCanEdit: (state, action: PayloadAction<boolean>) => {
      state.canEdit = action.payload
    },
    setCapabilities: (state, action: PayloadAction<CapabilityList | undefined>) => {
      state.capabilities = action.payload
    },
    setChannels: (state, action: PayloadAction<StateChannels>) => {
      state.channels = action.payload
    },
    setChannelsCodeTypeMap: (state, action: PayloadAction<ChannelCodeTypeMap | undefined>) => {
      state.channelsCodeTypeMap = action.payload
    },
    setChannelsEditLayerId: (state, action: PayloadAction<string | undefined>) => {
      state.channelsEditLayerId = action.payload
    },
    setDisallowDnd: (state, action: PayloadAction<boolean>) => {
      state.disallowDnd = action.payload
    },
    setForceRefetchFlag: (state, action: PayloadAction<boolean>) => {
      state.forceRefetchFlag = action.payload
    },
    setHistoryVersionPreviewMode: (
      state,
      action: PayloadAction<'backForward' | 'time' | undefined>,
    ) => {
      state.historyVersionPreviewMode = action.payload
    },
    setIsBackEnable: (state, action: PayloadAction<boolean>) => {
      state.isBackEnable = action.payload
    },
    setIsChangeRunning: (state, action: PayloadAction<boolean>) => {
      state.isChangeRunning = action.payload
    },
    setIsForwardEnable: (state, action: PayloadAction<boolean>) => {
      state.isForwardEnable = action.payload
    },
    setIsHistoryVersionPreview: (state, action: PayloadAction<boolean>) => {
      state.isHistoryVersionPreview = action.payload
    },
    setIsManagementOpen: (state, action: PayloadAction<boolean>) => {
      state.isManagementOpen = action.payload
    },
    setJourneyData: (state, action: PayloadAction<JourneyData | undefined>) => {
      state.journeyData = action.payload
    },
    setJourneyName: (state, action: PayloadAction<string | undefined>) => {
      state.selectedJourneyName = action.payload
    },
    setJourneySignature: (state, action: PayloadAction<JourneySignature | undefined>) => {
      state.journeySignature = action.payload
    },
    setJourneysStructure: (state, action: PayloadAction<JourneyModelStructure | undefined>) => {
      state.journeysStructure = action.payload
    },
    setLockedLayers: (state, action: PayloadAction<LockedLayers>) => {
      state.lockedLayers = action.payload
    },
    setSelectedJourneyId: (state, action: PayloadAction<number | undefined>) => {
      state.selectedJourneyId = action.payload
    },
    setTenantLimits: (state, action: PayloadAction<AdministrationTenant['limits'] | undefined>) => {
      state.tenantLimits = action.payload
    },
    setUserStories: (state, action: PayloadAction<UserStory[]>) => {
      state.userStories = action.payload
    },
    setWsError: (state, action: PayloadAction<WsEnhancedErrorMessage['error'] | undefined>) => {
      state.wsError = action.payload
    },
  },
})
