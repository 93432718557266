import { css } from '@emotion/css'
import { colors, theme, typography, typographyFontWeight } from '@dis/styles'

export const styles = {
  buttonContainer: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    gap: ${theme.spacing(2.5)};
  `,
  checkbox: css`
    padding-top: ${theme.spacing(0.5)};
    padding-bottom: ${theme.spacing(0.5)};
    padding-inline-start: 0;
    padding-inline-end: ${theme.spacing(2)};
  `,
  checkboxControl: css`
    margin-inline-start: 0;
  `,
  checkboxOption: css`
    padding-inline-start: ${theme.spacing(0)};
  `,
  checkboxSubOption: css`
    padding-inline-start: ${theme.spacing(4)};
  `,
  checkboxTest: css`
    padding: ${theme.spacing(5)};
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: ${theme.spacing(3)};
  `,
  checkboxesContainer: css`
    & > *:not(:first-child) {
      margin-inline-start: ${theme.spacing(2)};
    }
  `,
  closeBtn: css`
    cursor: pointer;
  `,
  filterCount: css`
    border-radius: 100%;
    padding: ${theme.spacing(0)};
    margin: ${theme.spacing(0)};
    width: 1.625rem;
    height: 1.625rem;
    min-width: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    font-weight: ${typographyFontWeight.semibold};
    font-size: ${typography.paragraph.body.fontSize};
    line-height: ${typography.paragraph.body.lineHeight};
    background-color: ${colors.kpmg.pacificBlue};
    border-color: ${colors.kpmg.pacificBlue};
    color: ${colors.background.white};
  `,
  mainButton: {
    btnBorderColor: css`
      border-color: ${colors.kpmg.pacificBlue} !important;
    `,
    icon: (iconSize: string) => css`
      svg {
        color: ${colors.gray.gray100};
        width: ${iconSize};
        height: ${iconSize};
      }
    `,
    iconWithMargin: css`
      margin-inline-end: ${theme.spacing(2)};
    `,
    label: (fontSize: string, fontWeight: number) => css`
      font-size: ${fontSize};
      font-weight: ${fontWeight};
    `,
    root: css`
      height: 2.5rem;
      color: ${colors.gray.gray80};
      justify-content: flex-start;
      align-items: center;
      padding: ${theme.spacing(2.5)} ${theme.spacing(4)};
      font-weight: ${typographyFontWeight.regular};
      border-radius: 8px;
      border: 1px solid ${colors.gray.gray10};
      gap: ${theme.spacing(2)};
      &:hover {
        border-color: ${colors.kpmg.pacificBlue} !important;
      }
    `,
  },
  popoverChildren: css`
    width: 19rem;
    max-width: 100%;
  `,
  popoverChildrenContent: css`
    border-top: 1px solid ${colors.gray.gray5};
    border-bottom: 1px solid ${colors.gray.gray5};
  `,
  popoverChildrenFooter: css`
    padding: ${theme.spacing(4)};
  `,
  popoverChildrenHeader: css`
    display: flex;
    justify-content: space-between;
    padding: ${theme.spacing(4)};
  `,
}
