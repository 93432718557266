import { FC, SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { Button, Popover } from '@mui/material'
import { cx } from '@emotion/css'
import Filter from '@dis/assets/src/icons/Filter.svg'
import { colors, getTypographyProp, typography, typographyFontWeight } from '@dis/styles'
import { tKeys } from '@dis/languages'
import Close from '@dis/assets/src/icons/Close.svg'
import { useAnchor } from '@dis/hooks/src/useAnchor'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '@dis/redux'
import { selectIsLanguageRtl } from '@dis/redux/src/user/selectors'
import { PopoverFilterCheckbox } from '@dis/types/src/PopoverFilterSortTypes'
import { styles } from './styles'
import { Paragraph } from '../Typography/Paragraph'
import { Checkbox } from '../Checkbox/Checkbox'
import { TextIconButton } from '../TextIconButton/TextIconButton'
import { SvgImage } from '../SvgImage/SvgImage'

type Checkboxes = Array<Array<PopoverFilterCheckbox>>

export type PopoverFilterProps = {
  buttonClassname?: string
  checkboxes: Checkboxes
  onApplyFilters: (updatedCheckboxes: Checkboxes) => void
  popoverClassname?: string
  titles: Array<string>
}

export const PopoverFilter: FC<PopoverFilterProps> = ({
  buttonClassname,
  checkboxes,
  onApplyFilters,
  popoverClassname,
  titles,
}) => {
  const [selectAllCheckbox, setSelectAllCheckbox] = useState<boolean[]>([true])
  const [tempCheckboxes, setTempCheckboxes] = useState<Checkboxes>(checkboxes)

  const { t } = useTranslation()

  const { anchorEl, handleClose, handleOpen } = useAnchor()

  const isRtl = useAppSelector(selectIsLanguageRtl)

  const init = useCallback(() => {
    if (!checkboxes.length) {
      setTempCheckboxes([
        [
          {
            attr: 'all',
            checked: true,
            label: 'All',
          },
        ],
      ])
    } else {
      const newTempState = checkboxes.map((item) => [...item.map((subItem) => ({ ...subItem }))])

      const newBoolState: boolean[] = []

      // Deep copy
      checkboxes.forEach((item) => {
        let newBoolSubState = true
        item.map((nestedItem) => {
          newBoolSubState = newBoolSubState && !!nestedItem.checked
        })

        newBoolState.push(newBoolSubState)
      })

      setTempCheckboxes(newTempState)
      setSelectAllCheckbox(newBoolState)
    }
  }, [checkboxes])

  const handleClosePopover = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation()
      handleClose()
    },
    [handleClose],
  )

  const handleCheckboxChange = useCallback(
    (sIndex: number, chIndex: number) => () => {
      setTempCheckboxes((previousState) => {
        if (previousState) {
          const newState = [...previousState]
          let newBoolState = true

          newState[sIndex][chIndex] = {
            ...newState[sIndex][chIndex],
            checked: !newState[sIndex][chIndex]?.checked,
          }

          newState.forEach((item) => {
            item.forEach((nestedItem) => {
              newBoolState = newBoolState && !!nestedItem.checked
            })
          })

          setSelectAllCheckbox((previousBoolArrayState) => {
            const newBoolArrayState = [...previousBoolArrayState]

            newBoolArrayState[sIndex] = newBoolState

            return newBoolArrayState
          })

          return newState
        }

        return previousState
      })
    },
    [],
  )

  const handleAllCheckboxChange = useCallback(
    (sIndex: number) => () => {
      setSelectAllCheckbox((previousBoolArrayState) => {
        const newBoolArrayState = [...previousBoolArrayState]
        newBoolArrayState[sIndex] = !newBoolArrayState[sIndex]

        setTempCheckboxes((checkboxesPreviousState) => {
          const newState = [...checkboxesPreviousState]

          newState[sIndex].forEach((item) => {
            item.checked = newBoolArrayState[sIndex]
          })

          return newState
        })

        return newBoolArrayState
      })
    },
    [],
  )

  const handleApplyFilters = useCallback(() => {
    if (tempCheckboxes) {
      onApplyFilters(tempCheckboxes)
    }
    handleClose()
  }, [handleClose, onApplyFilters, tempCheckboxes])

  useEffect(() => {
    init()
  }, [init])

  const appliedCount = checkboxes.reduce(
    (count, subCheckboxes) => (count += subCheckboxes.filter(({ checked }) => checked).length),
    0,
  )

  return (
    <>
      <Button
        className={cx(
          styles.mainButton.root,
          { [styles.mainButton.btnBorderColor]: !!anchorEl },
          buttonClassname,
        )}
        color="secondary"
        onClick={handleOpen}>
        <span>
          <SvgImage
            src={Filter}
            className={styles.mainButton.icon(getTypographyProp('header.h2', 'fontSize'))}
          />
        </span>
        <span
          className={styles.mainButton.label(
            getTypographyProp('paragraph.body', 'fontSize'),
            typographyFontWeight.semibold,
          )}>
          {t(tKeys.common.filter)}
        </span>
        {appliedCount > 0 && (
          <span>
            <div className={styles.filterCount}>{appliedCount}</div>
          </span>
        )}
      </Button>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        className={popoverClassname}
        onClose={handleClosePopover}
        anchorOrigin={{
          horizontal: isRtl ? 'left' : 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: isRtl ? 'left' : 'right',
          vertical: 'top',
        }}>
        <div className={styles.popoverChildren}>
          <div className={styles.popoverChildrenHeader}>
            <Paragraph variant="medium" fontWeight="bold" color={colors.gray.gray80}>
              {t(tKeys.common.filters)}
            </Paragraph>
            <div onClick={handleClosePopover} className={styles.closeBtn}>
              <SvgImage color={colors.gray.gray40} src={Close} />
            </div>
          </div>
          <div className={styles.popoverChildrenContent}>
            {tempCheckboxes?.map((checkboxes, sIndex) => {
              return (
                <div key={titles[sIndex]} className={styles.checkboxTest}>
                  <Paragraph color={colors.gray.gray60}>{titles[sIndex]}</Paragraph>

                  <div className={styles.checkboxOption}>
                    <Checkbox
                      className={styles.checkboxControl}
                      checkboxClassName={styles.checkbox}
                      value={selectAllCheckbox[sIndex]}
                      indeterminate={
                        checkboxes.some(({ checked }) => checked) &&
                        checkboxes.some(({ checked }) => !checked)
                      }
                      inlineLabel={t(tKeys.common.filtering.selectAll)}
                      inlineLabelSx={{
                        fontSize: typography.paragraph.body.fontSize,
                      }}
                      onChange={handleAllCheckboxChange(sIndex)}
                    />
                  </div>

                  {checkboxes.map((checkbox, chIndex) => (
                    <div key={checkbox.attr} className={styles.checkboxSubOption}>
                      <Checkbox
                        className={styles.checkboxControl}
                        checkboxClassName={styles.checkbox}
                        value={checkbox.checked}
                        onChange={handleCheckboxChange(sIndex, chIndex)}
                        inlineLabel={checkbox.labelTkey ? t(checkbox.labelTkey) : checkbox.label}
                        inlineLabelSx={{
                          fontSize: typography.paragraph.body.fontSize,
                        }}
                      />
                    </div>
                  ))}
                </div>
              )
            })}
          </div>
          <div className={styles.popoverChildrenFooter}>
            <div className={styles.buttonContainer}>
              <TextIconButton
                fullWidth
                text={t(tKeys.common.reset)}
                variant="outlined"
                onClick={init}
                fontSize="paragraph.body"
              />
              <TextIconButton
                fullWidth
                text={t(tKeys.common.filtering.applyFilter)}
                onClick={handleApplyFilters}
                fontSize="paragraph.body"
              />
            </div>
          </div>
        </div>
      </Popover>
    </>
  )
}
