import { css } from '@emotion/css'
import { colors, theme, typographyFontWeight } from '@dis/styles'
import { shadowClasses } from '@dis/styles/src/boxShadows'

export const roundIconDelete = css`
  opacity: 0;
  transition: opacity 0.5s;
  position: absolute;
  bottom: 3.5rem;
  right: 3.5rem;
`

export const styles = {
  body: css`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    position: relative;
    width: 100%;
    max-width: 100%;
  `,

  createdContainer: css`
    opacity: 1;
    z-index: 1;
    transition: opacity ease-in-out 0.2s;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 0 0 auto;

    & > * {
      margin-top: ${theme.spacing(4)};
    }
  `,

  editTemplateModal: css`
    display: flex;
    flex-direction: column;
  `,
  editTenantModal: css`
    display: flex;
    flex-direction: column;
  `,

  font: css`
    color: ${colors.semantic.green};
  `,

  middleContainer: css`
    flex: 1 1 auto;
    padding-top: ${theme.spacing(2)};
    position: relative;
  `,
  modalCheckbox: css`
    align-self: start;
    margin-bottom: -${theme.spacing(6)};
    margin-inline-start: -${theme.spacing(6)};
    margin-top: ${theme.spacing(6)};
  `,
  nameContainer: css`
    cursor: default;
  `,
  paper: css`
    margin-bottom: ${theme.spacing(2)};
    background: ${colors.transparent};
  `,
  popoverSortAndFilter: {
    closeBtn: css`
      cursor: pointer;
    `,

    itemButton: {
      icon: (iconSize: string) => css`
        svg {
          color: ${colors.gray.gray100};
          width: ${iconSize};
          height: ${iconSize};
        }
      `,
      label: (fontSize: string) => css`
        font-size: ${fontSize};
      `,
      root: css`
        justify-content: flex-start;
        align-items: center;
        padding: ${theme.spacing(4)} ${theme.spacing(4)};
        font-weight: ${typographyFontWeight.regular};
        border-radius: 8px;
        border: 1px solid ${colors.gray.gray10};
        gap: ${theme.spacing(2)};
      `,
    },
    popoverChildren: css`
      width: 25rem;
    `,

    popoverChildrenContent: css`
      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: ${theme.spacing(4)};
    `,

    popoverChildrenContentCheckboxes: css`
      display: flex;
    `,
    popoverChildrenContentSortBy: css`
      padding: ${theme.spacing(4)};
    `,
    popoverChildrenContentSortByRadio: css`
      display: flex;
      flex-direction: column;
    `,
    popoverChildrenHeader: css`
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid ${colors.gray.gray5};
      padding: ${theme.spacing(4)};
    `,
    sortByClear: css`
      color: ${colors.gray.gray40};
      cursor: pointer;
      margin-top: ${theme.spacing(2)};
    `,
  },
  root: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0.5;
  `,
  rootInitialized: css`
    opacity: 1;
    cursor: pointer;
  `,
  selectSector: css`
    height: 14rem;
    min-height: 14rem;
  `,
  tenantIcon: css`
    position: relative;
    width: fit-content;
    border-radius: 9999px;
    margin-bottom: ${theme.spacing(2)};

    &:hover ${'.' + roundIconDelete} {
      opacity: 1;
    }

    &:hover {
      cursor: pointer;
      ${shadowClasses['4']};
      transition: 0.8s;
    }
  `,
}
