import { yup } from '@dis/form'
import { CreateOrEditTenantForm } from '@dis/types/src/forms'
import { MAX_TENANT_DESCRIPTION } from '@dis/constants'
import { yupTenantNameValidation } from './utils'

export const validationSchema: yup.ObjectSchema<CreateOrEditTenantForm> = yup.object({
  description: yup.string().optional().trim().max(MAX_TENANT_DESCRIPTION),
  sectorcode: yup.string().required(),
  tenantName: yupTenantNameValidation,
})
