/**
 * @generated SignedSource<<3b6d19eb512993e6f30ca162871dc4be>>
 * @relayHash 4634dcb337cea9811aba4d269b5b72ae
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID d1a074f0bca475ce8055ca4bdfa27ba011cb47156a6fb3c78c305fe47fdc5582

import { ConcreteRequest } from 'relay-runtime';
export type JourneyCommentStatus = "none" | "opened" | "resolved" | "%future added value";
export type JourneyResourceType = "cell" | "layer" | "%future added value";
export type JourneyCommentListQueryInput = {
  journeyId: number;
  tenantId: number;
  threadId: string;
};
export type QueriesGetJourneyCommentListQuery$variables = {
  input: JourneyCommentListQueryInput;
};
export type QueriesGetJourneyCommentListQuery$data = {
  readonly journeyCommentList: {
    readonly items: ReadonlyArray<{
      readonly content: string;
      readonly created: string;
      readonly id: string;
      readonly metadata: {
        readonly userInfo: {
          readonly email: string;
          readonly name: string;
          readonly oid: string;
        };
      };
      readonly resourceId: string;
      readonly resourceType: JourneyResourceType;
      readonly status: JourneyCommentStatus;
      readonly threadCommentId: string | null | undefined;
      readonly updated: string | null | undefined;
    }>;
    readonly threadOwnershipDisabled: boolean;
  };
};
export type QueriesGetJourneyCommentListQuery = {
  response: QueriesGetJourneyCommentListQuery$data;
  variables: QueriesGetJourneyCommentListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "JourneyCommentListQueryResult",
    "kind": "LinkedField",
    "name": "journeyCommentList",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "threadOwnershipDisabled",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "JourneyCommentDto",
        "kind": "LinkedField",
        "name": "items",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "content",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "created",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updated",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "JourneyCommentMetadataDto",
            "kind": "LinkedField",
            "name": "metadata",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JourneyCommentUserMetadataDto",
                "kind": "LinkedField",
                "name": "userInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "oid",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "resourceId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "resourceType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "threadCommentId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QueriesGetJourneyCommentListQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QueriesGetJourneyCommentListQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "d1a074f0bca475ce8055ca4bdfa27ba011cb47156a6fb3c78c305fe47fdc5582",
    "metadata": {},
    "name": "QueriesGetJourneyCommentListQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "5d69f0f3b7acf5e7420f1b2cead4a69e";

export default node;
