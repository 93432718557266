import { useEffect } from 'react'
import { dispatchedActions } from '@dis/redux'
import { useSearch } from '@dis/hooks/src/useSearch'
import { Input, useForm } from '@dis/form'
import { styles } from '@dis/components/src/SearchBar/styles'
import { useTranslation } from 'react-i18next'
import { tKeys } from '@dis/languages'
import SearchIcon from '@dis/assets/src/icons/SearchIcon.svg'
import { SvgImage } from '@dis/components'
import { colors } from '@dis/styles'
import { emptyResults, SearchForm, searchBarValidationSchema } from './utils'

const ResultsSearchBar = () => {
  const { t } = useTranslation()

  const { sendSearch } = useSearch()

  useEffect(() => {
    return () => {
      dispatchedActions.search.setSearchWord('')
      dispatchedActions.search.setSearchResults(emptyResults)
    }
  }, [])

  const { control: formControl, handleSubmit } = useForm<SearchForm>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    validationSchema: searchBarValidationSchema,
  })

  const handleSearch = handleSubmit((data) => {
    sendSearch(data.search)
  })

  return (
    <form onSubmit={handleSearch}>
      <Input
        name="search"
        formControl={formControl}
        onBlur={handleSearch}
        className={styles.resultsSearchBarContainer}
        placeholder={t(tKeys.applicationMenu.searching)}
        icon={
          <SvgImage color={colors.gray.gray40} src={SearchIcon} className={styles.resultPointer} />
        }
        variant="outlined"
        width="140px"
      />
    </form>
  )
}

export default ResultsSearchBar
